import { createStore } from 'vuex';
import axios from 'axios';

export default createStore({
  state: {
    storeConfigurationData: null,
    storeInformationData: null,
    storeMenuData: null,
    showButton: true,
    isMainMenuVisible: false,
    isCategoryPopupVisible: false
  },
  mutations: {
    startStoreConfigurationFetch(state) {
      state.isStoreConfigurationLoading = true;
    },
    endStoreConfigurationFetch(state) {
      state.isStoreConfigurationLoading = false;
    },
    startStoreInformationFetch(state) {
      state.isStoreInformationLoading = true;
    },
    endStoreInformationFetch(state) {
      state.isStoreInformationLoading = false;
    },
    startStoreMenuFetch(state) {
      state.isStoreMenuLoading = true;
    },
    endStoreMenuFetch(state) {
      state.isStoreMenuLoading = false;
    },
    SET_STORE_CONFIGURATION_DATA(state, payload) {
      state.storeConfigurationData = payload;
    },
    SET_STORE_INFORMATION_DATA(state, payload) {
      state.storeInformationData = payload;
    },
    SET_STORE_MENU_DATA(state, payload) {
      state.storeMenuData = payload;
    },
    setShowButton(state, show) {
      state.showButton = show;
    },
    SET_MAIN_MENU_VISIBLE(state, isVisible) {
      state.isMainMenuVisible = isVisible;
    },
    SET_CATEGORY_POPUP_VISIBLE(state, isVisible) {
      state.isCategoryPopupVisible = isVisible;
    }
  },
  actions: {
    fetchStoreConfiguration({ commit, state }) {
      if (!state.storeConfigurationData && !state.isStoreConfigurationLoading) {
          commit('startStoreConfigurationFetch');
          axios.get('/get-store-configurations', { responseType: 'json' })
            .then(response => {
              commit('SET_STORE_CONFIGURATION_DATA', response.data.data);
              commit('endStoreConfigurationFetch');
            })
            .catch(error => {
              commit('endStoreConfigurationFetch');
              return error
            });
      } else {
        return Promise.resolve(state.storeConfigurationData); // Return existing data if already fetched
      }
    },
    fetchStoreInformation({ commit, state }) {
      if (!state.storeInformationData && !state.isStoreInformationLoading) {
          commit('startStoreInformationFetch');
          axios.get('/get-store-information', { responseType: 'json' })
            .then(response => {
              commit('SET_STORE_INFORMATION_DATA', response.data.data[0]);
              commit('endStoreInformationFetch');
            })
            .catch(error => {
              commit('endStoreInformationFetch');
              return error
            });
      } else {
        return Promise.resolve(state.storeInformationData); // Return existing data if already fetched
      }
    },
    fetchStoreMenu({ commit, state }) {
      if (!state.storeMenuData && !state.isStoreMenuLoading) {
          commit('startStoreMenuFetch');
          axios.get('/get-store-menues', { responseType: 'json' })
            .then(response => {
              commit('SET_STORE_MENU_DATA', response.data.data);
              commit('endStoreMenuFetch');
            })
            .catch(error => {
              commit('endStoreMenuFetch');
              return error
            });
        } else {
          return Promise.resolve(state.storeMenuData); // Return existing data if already fetched
        }
    },
    toggleButtonVisibility({ commit }, isInView) {
      commit('setShowButton', !isInView);
    },
    setMainMenuVisible({ commit }, isVisible) {
      commit('SET_MAIN_MENU_VISIBLE', isVisible);
    },
    setCategoryPopupVisible({ commit }, isVisible) {
      commit('SET_CATEGORY_POPUP_VISIBLE', isVisible);
    }
  },
  getters: {
    showButton: (state) => state.showButton
  }
});