<template>
  <layout>
    <section class="breadcrumbs-bg">
        <div class="container">
            <h1>404 - Page Not Found</h1>
        </div>
    </section>

    <section class="inner-page-section py-5">
        <div class="container">
            <p class="text-center">The page you are looking for does not exist.</p>
        </div>
    </section>
  </layout>
</template>
 
<script>
import Layout from '../Layout/Layout.vue';
 
export default {
  name: 'NotFoundComponent',
  components: {
    Layout
  },
  created() {
    document.title = 'LXG - Page Not Found'
  },
};
</script>