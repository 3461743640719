<template>
  <footer class="footer-bg">
    <div class="container footer-txt pt-4">
      <div class="row">
        <div class="col-12 col-md-6 col-lg-4 col-xl-3">
          <div class="footer-logo mb-3">
            <img width="167" height="106" :src="getImgUrl()" alt="Footer Logo">
          </div>
        </div>

        <div class="col-12 col-md-6 col-lg-4 col-xl-3" v-for="menu in filteredMenuData" :key="menu.item_id">
          <div v-if="menu.menu_location === 3">
            <div class="position-relative">
              <h5 class="footer-heading">{{menu.item_text}}</h5>
            </div>
            <ul class="mpl-zero footer-heading-menu" :id="'footerMenu_' + menu.item_id" v-if="menu.submenu && menu.submenu.length">
              <li v-for="smenu in menu.submenu" :key="smenu.item_id">
                <a :target="smenu.item_target != null && smenu.item_target != '' ? `${smenu.item_target}` : '_self'" :href="smenu.item_link != null ? `/${smenu.item_link}` : '#'" class="nav-link">{{smenu.item_text}}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row copyright-area">
          <div class="col-md-auto">
            <ul class="mpl-zero d-flex align-items-center py-3">
              <li v-for="menu in filteredbottomMenuData" :key="menu.item_id">
                <a :target="menu.item_target != null && menu.item_target != '' ? `${menu.item_target}` : '_self'" :href="menu.item_link != null ? `/${menu.item_link}` : '#'">{{menu.item_text}}</a>
              </li>
            </ul>
          </div>
          <div class="col-md-auto ms-auto">
            <div class="col-12 text-center py-3">
              <p class="m-0" v-if="storeConfigurationData"><small>{{ storeConfigurationData.copy_right }}</small></p>
            </div>
          </div>
        </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'FooterComponent',
  data() {
    return {
        imageVersion: 1
    }
  },
  computed: {
    storeConfigurationData() {
      return this.$store.state.storeConfigurationData;
    },
    storeMenuData() {
      return this.$store.state.storeMenuData;
    },
    filteredMenuData() {
      return this.storeMenuData ? this.storeMenuData.filter(menu => menu.menu_location === 3) : [];
    },
    filteredbottomMenuData() {
      return this.storeMenuData ? this.storeMenuData.filter(menu => menu.menu_location === 2) : [];
    }
  },
  created() {
    if (!this.storeConfigurationData) {
      this.$store.dispatch('fetchStoreConfiguration');
    }
    if (!this.storeMenuData) {
      this.$store.dispatch('fetchStoreMenu');
    }
  },
  async mounted() {
    await this.fetchImageVersion();
  },
  methods: {
    async fetchImageVersion(){
      if (!this.storeConfigurationData) {
        this.$store.dispatch('fetchStoreConfiguration');
      }
    },
    getImgUrl(){
      if(this.storeConfigurationData){
        this.imageVersion = this.storeConfigurationData.css_js_version;
      }
      return process.env.VUE_APP_IMG_URL+'/images/store_images/footer_logo.png?v='+this.imageVersion;
    }
  }
}
</script>