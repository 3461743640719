<template>
  <section class="home-section-bg get-in-touch-bg" id="get_in_touch_id" v-is-in-viewport="checkInView">
    <div class="container">
        <div class="row">
            <div class="col-xl-7 col-lg-9 col-md-10 mx-auto">
                <div class="section-title-grp text-center">
                    <div class="section-title">
                        <h2>Ready To Get In Touch</h2>
                        <p v-if="storeConfigurationData">Toll Free: <a href="#">{{ storeConfigurationData.contact_number }}</a></p>
                    </div>
                    <div class="row address-detail-bg" v-if="contacts">
                        <div class="col-md-6" v-for="contact in contacts" :key="contact.id">
                            <div class="address-box">
                                <h5>{{ contact.name }}</h5>
                                <p>({{ contact.designation }})<br />{{ contact.email }}<br />{{ contact.phone }}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="contact-form-bg pt-4">
                    <div class="alert alert-success fade show text-center" role="alert" v-if="isSuccess">
                        {{ successMessage }}
                        <!--<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>-->
                    </div>
                    <div class="alert alert-danger fade show text-center" role="alert" v-if="isError">
                        {{ errorMessage }}
                        <!--<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>-->
                    </div>
                    <form @submit.prevent="submitForm">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="mb-3">
                                    <label class="form-label">Your name</label>
                                    <input type="text" class="form-control" id="" placeholder="Name" v-model="form.fullname" @input="validateForm">
                                    <span class="invalid-feedback d-block" v-if="fullnameError">{{ fullnameError }}</span>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="mb-3">
                                    <label class="form-label">Email address</label>
                                    <input type="email" class="form-control" id="" placeholder="Email" v-model="form.email" @input="validateForm">
                                    <span class="invalid-feedback d-block" v-if="emailError">{{ emailError }}</span>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="mb-3">
                                    <label class="form-label">Company</label>
                                    <input type="text" class="form-control" id="" placeholder="Company Name" v-model="form.company" @input="validateForm">
                                    <span class="invalid-feedback d-block" v-if="companyError">{{ companyError }}</span>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="mb-3">
                                    <label class="form-label">Phone Number</label>
                                    <input type="text" class="form-control" id="" placeholder="Phone Number" v-model="form.phone" @input="validateForm">
                                    <span class="invalid-feedback d-block" v-if="phoneError">{{ phoneError }}</span>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="mb-3">
                                    <label class="form-label">Message</label>
                                    <textarea class="form-control" id="" rows="6" v-model="form.message" @input="validateForm"></textarea>
                                    <span class="invalid-feedback d-block" v-if="messageError">{{ messageError }}</span>
                                </div>
                            </div>
                            <div class="col-md-12 text-center pt-3">
                                <button type="submit" class="btn reverse-glb-btn contact_sbmt_btn" :disabled="validationError">Submit <span v-if="isLoading" class="cloader"></span></button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
   </section>
</template>
<script>
import axios from 'axios';
export default {
  name: 'GetInTouchElement',
  computed: {
    storeConfigurationData() {
      return this.$store.state.storeConfigurationData;
    },
    storeInformationData() {
      return this.$store.state.storeInformationData;
    },
    storeData() {
      return {
        configuration: this.$store.state.storeConfigurationData,
        information: this.$store.state.storeInformationData
      };
    }
  },
  watch: {
    storeData: {
      handler(newVal, oldVal) {
        if (newVal.configuration && newVal.configuration.captcha_key && newVal.information && newVal.information.enable_captcha) {
          this.executeRecaptcha(newVal, oldVal);
        }
      },
      deep: true
    }
  },
  data() {
    return {
      contacts: [],
      form: {
        fullname: '',
        email: '',
        company: '',
        phone: '',
        message: '',
        recaptchaToken: '',
        _csrf: ''
      },
      fullnameError: null,
      emailError: null,
      companyError: null,
      phoneError: null,
      messageError: null,
      validationError: null,
      isSuccess: false,
      isError: false,
      successMessage: '',
      errorMessage: '',
      isLoading: false,
      csrfToken: ''
    }
  },
  created() {
    this.fetchContactList();
    if (!this.storeConfigurationData) {
      this.$store.dispatch('fetchStoreConfiguration');
    }
    if (!this.storeInformationData) {
      this.$store.dispatch('fetchStoreInformation');
    }
  },
  methods: {
    validateForm() {
      if (this.form.fullname === '') {
        this.fullnameError = 'Please enter your name';
        this.validationError = 'invalid';
        return false;
      } else if (this.form.fullname.length > 200) {
        this.fullnameError = 'Maximum 200 characters allowed';
        this.validationError = 'invalid';
        return false;
      } else {
        this.fullnameError = null;
        this.validationError = null;
      }

      const emailPattern = /\S+@\S+\.\S+/;
      if ((this.form.email && !emailPattern.test(this.form.email)) || this.form.email === '') {
        this.emailError = 'Please enter a valid email address';
        this.validationError = 'invalid';
        return false;
      } else if (this.form.email.length > 200) {
        this.emailError = 'Maximum 200 characters allowed';
        this.validationError = 'invalid';
        return false;
      } else {
        this.emailError = null;
        this.validationError = null;
      }

      if (this.form.company === '') {
        this.companyError = 'Please enter your company name';
        this.validationError = 'invalid';
        return false;
      } else if (this.form.company.length > 200) {
        this.companyError = 'Maximum 200 characters allowed';
        this.validationError = 'invalid';
        return false;
      } else {
        this.companyError = null;
        this.validationError = null;
      }

      if (this.form.phone !== '' && this.form.phone.length > 15) {
        this.phoneError = 'Maximum 15 characters allowed';
        this.validationError = 'invalid';
        return false;
      } else {
        this.phoneError = null;
        this.validationError = null;
      }

      if (this.form.message !== '' && this.form.message.length > 2000) {
        this.messageError = 'Maximum 2000 characters allowed';
        this.validationError = 'invalid';
        return false;
      } else {
        this.messageError = null;
        this.validationError = null;
      }
      return true;
    },
    // eslint-disable-next-line no-unused-vars
    executeRecaptcha(newStoreInfo, oldStoreInfo) {
      if (newStoreInfo.information.enable_captcha === 'yes') {
        this.loadRecaptcha(newStoreInfo.configuration.captcha_key)
          .then(() => {
            if (typeof grecaptcha !== 'undefined') {
              grecaptcha.ready(() => {
                grecaptcha.execute(newStoreInfo.configuration.captcha_key, { action: 'submit' }).then((token) => {
                  this.form.recaptchaToken = token;
                });
              });
            } else {
              console.error('reCAPTCHA not loaded');
            }
          })
          .catch(error => {
            console.error('Error loading reCAPTCHA:', error);
          });
      }
    },
    async submitForm() {
      const isValidForm = this.validateForm();
      if (isValidForm) {
        this.isLoading = true;

        this.form._csrf = this.csrfToken;
        try {
          const response = await axios.post('/save-contact', this.form, {
            headers: {
              'Content-Type': 'application/json'
            }
          });
          if (response.data.data) {
            this.isSuccess = true;
            this.isError = false;
            this.successMessage = 'Thank you for contacting us. We received your message and will get in touch within 2 business days.';
            this.errorMessage = '';
          } else {
            this.isSuccess = false;
            this.isError = true;
            this.successMessage = '';
            this.errorMessage = response.data.message;
          }
          this.form = { fullname: '', email: '', company: '', phone: '', message: '', recaptchaToken: '' };
        } catch (error) {
          console.error('There was an error!', error);
          this.isSuccess = false;
          this.isError = true;
          this.successMessage = '';
          this.errorMessage = 'Something went wrong. Please try again.';
          this.form = { fullname: '', email: '', company: '', phone: '', message: '', recaptchaToken: '' };
        } finally {
          this.isLoading = false;
          setTimeout(() => {
            this.isSuccess = false;
            this.isError = false;
            this.successMessage = '';
            this.errorMessage = '';
          }, 5000);
          this.getCSRFToken();
        }
      } else {
        this.isSuccess = false;
        this.isError = true;
        this.successMessage = '';
        this.errorMessage = 'Please correct errors before submitting this form.';
      }
    },
    async fetchContactList() {
      try {
        const response = await axios.get('/get-contact-details', { responseType: 'json' });
        this.contacts = response.data.data;
      } catch (error) {
        console.error('Error fetching contact list:', error);
      }
    },
    loadRecaptcha(site_key) {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = 'https://www.google.com/recaptcha/api.js?render=' + site_key;
        script.onload = resolve;
        script.onerror = reject;
        document.head.appendChild(script);
      });
    },
    async getCSRFToken() {
      try {
        const resp = await axios.get('/set-csrf-token');
        this.csrfToken = resp.data.csrfToken;
      } catch (error) {
        console.error('Error fetching CSRF token:', error);
      }
    },
    checkInView(isInView) {
      this.$store.dispatch('toggleButtonVisibility', isInView);
    }
  },
  mounted() {
    this.getCSRFToken();
  }
}
</script>