export default {
  mounted(el, binding) {
    // Function to check if element is in viewport
    el.isInViewport = () => {
      const rect = el.getBoundingClientRect();
      const viewHeight = window.innerHeight || document.documentElement.clientHeight;
      return !(rect.bottom < 0 || rect.top - viewHeight >= 0);
    };

    // Function to handle scroll or resize events
    const onScrollOrResize = () => {
      const isInView = el.isInViewport();
      const isAtTop = window.scrollY === 0;

      if (isAtTop) {
        // If at the top of the page, ensure element is hidden
        if (el.id !== 'get_in_touch_id') {
          el.classList.remove('fade-in');
          el.classList.add('fade-out');
        }
        binding.value(false);
      } else if (isInView && !el.classList.contains('fade-in')) {
        if (el.id !== 'get_in_touch_id') {
          el.classList.remove('fade-out');
          el.classList.add('fade-in');
        }
        binding.value(true);  // Element is in viewport
      } else if (!isInView && !el.classList.contains('fade-out')) {
        if (el.id !== 'get_in_touch_id') {
          el.classList.remove('fade-in');
          el.classList.add('fade-out');
        }
        binding.value(false); // Element is not in viewport
      }
    };

    // Add scroll and resize event listeners
    window.addEventListener('scroll', onScrollOrResize);
    window.addEventListener('resize', onScrollOrResize);

    // Initial check on mount
    onScrollOrResize();

    // Clean up listeners on unmount
    el._onScrollOrResize = onScrollOrResize;
  },
  unmounted(el) {
    // Remove event listeners on unmount
    window.removeEventListener('scroll', el._onScrollOrResize);
    window.removeEventListener('resize', el._onScrollOrResize);
  }
};
