<template>
    <MaintenanceMessage v-if="isUnderMaintenance" />
    <router-view v-else />
</template>
 
<script>
import MaintenanceMessage from './components/Elements/MaintenanceMessage.vue';

export default {
  name: 'App',
  components: {
    MaintenanceMessage,
  },
  data() {
    return {
      isUnderMaintenance: process.env.VUE_APP_UNDER_MAINTENANCE === true,
    };
  },
};
</script>