<template>
  <div class="maintenance-message">
    <h1>Maintenance in Progress</h1>
    <p>We are currently performing maintenance. Please check back later.</p>
  </div>
</template>

<script>
export default {
  name: 'MaintenanceMessage',
};
</script>

<style>
.maintenance-message {
  text-align: center;
  margin-top: 20%;
  font-family: Arial, sans-serif;
}
</style>