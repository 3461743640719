<template>
  <li :class="['nav-item', { 'active': isActive(menuItem) }]">
    <a :target="menuItem.item_target || '_self'" :href="menuItem.item_link ? `/${menuItem.item_link}` : '#'" class="nav-link">{{ menuItem.item_text }}</a>
    <div class="child-trigger" :class="{ 'child-open': isOpen }" @click="toggleSubMenu" v-if="menuItem.submenu && menuItem.submenu.length">
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
        <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"/>
      </svg>
    </div>
    <transition name="slide">
      <ul v-if="isOpen" class="sub-menu mpl-zero">
        <SubmenuElement v-for="submenuItem in menuItem.submenu" :key="submenuItem.item_id" :menu-item="submenuItem"></SubmenuElement>
      </ul>
    </transition>
  </li>
</template>

<script>
import SubmenuElement from './SubmenuElement.vue';

export default {
  props: {
    menuItem: {
      type: Object,
      required: true
    }
  },
  components: {
    SubmenuElement
  },
  computed: {
    currentUrl() {
      return window.location.pathname;
    }
  },
  data() {
    return {
      isOpen: false
    };
  },
  methods: {
    toggleSubMenu() {
      this.isOpen = !this.isOpen;
    },
    isActive(menuItem) {
      return `/${menuItem.item_link}` === this.currentUrl;
    }
  }
};
</script>