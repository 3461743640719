<template>
	<section class="inner-page-section py-5">
		<div class="container">
			<p class="text-center">The page you are looking for does not exist.</p>
		</div>
	</section>
</template>
<script>
export default {
  name: 'NotFoundElement'
};
</script>