<template>
  <layout>
    <section class="hero-slider-bg">
      <div class="w-100 owl-carousel owl-theme" id="banner-inner-div" v-if="banners.length">
          <div class="item" v-for="(banner, index) in banners" :key="banner.banner_id">
              <div class="owl-text-overlay">
                  <div class="container">
                      <h1 class="owl-title" :class="getClass(parseJSON(banner.image_attributes).text_position)" v-html="bannerHeading(banner.image_attributes)"></h1>
                  </div>
              </div>
              <!--<img :src="require(`@/assets/${banner.store_banner}`)" :alt="'Home Banner ' + (index + 1)">-->
              <a v-bind="parseJSON(banner.image_attributes).banner_link != '' && parseJSON(banner.image_attributes).banner_link != null ? { target: '_blank' } : {}" :href="parseJSON(banner.image_attributes).banner_link != '' && parseJSON(banner.image_attributes).banner_link != null ? `${parseJSON(banner.image_attributes).banner_link}` : '#'"><img width="100%" height="100%" :src="getImgUrl(banner.store_banner)" :alt="'Home Banner ' + (index + 1)"></a>
          </div>
      </div>
    </section>

    <section class="home-section-bg home-category-bg" v-if="categories.length">
        <div class="container">
            <div class="cate-title text-center">
                <h2><span>Category</span></h2>
            </div>
            <div class="row pt-md-4">
                <div class="col-md-4" v-for="category in categories" :key="category.category_id">
                    <div class="cate-box pt-4 pt-md-0">
                        <div class="cate-img">
                            <a :href="`/products/${category.category_slug}`"><img width="100%" height="100%" :src="getImgUrl(category.category_image)" :alt="(category.category_name)"></a>
                        </div>
                        <div class="cate-txt">
                            <h3>{{category.category_name}}</h3>
                            <!--<a :href="`/products/${category.category_slug}`"><i class="fa fa-long-arrow-right" aria-hidden="true"></i></a>-->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
 
    <section class="home-section-bg home-perfect-bg" v-if="sectiononedata">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-md-5" v-if="sectiononedata.contenthtml">
                    <div class="section-title-grp" v-html="sectiononedata.contenthtml"></div>
                    <a :href="sectiononedata.button_link != '' ? `${sectiononedata.button_link}` : '#'" class="btn glb-btn">{{ sectiononedata.button_text }}</a>
                </div>
                <div class="col-md-7 pt-4 pt-md-0" v-if="sectiononedata.images">
                    <div class="owl-carousel owl-theme" id="perfect-slider">
                        <div class="item" v-for="(img, index) in sectiononedata.images" :key="index">
                            <img width="100%" height="100%" :src="getImgUrl(img)" :alt="'Perfect Package ' + (index + 1)">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

      <section class="home-section-bg home-text-img-bg position-relative" :style="backgroundImageStyle" v-if="sectiontwodata">
          <div class="container position-relative">
              <div class="row align-items-center">
                  <div class="col-md-auto">
                      <div class="section-title-grp">
                          <div v-html="sectiontwodata.contenthtml"></div>
                          <a :href="callPhone(sectiontwodata.button_link)" class="btn glb-btn">{{ sectiontwodata.button_text }}</a>
                      </div>
                  </div>
              </div>
          </div>
      </section>

      <section class="home-section-bg home-quality-bg" v-if="sectionthreedata">
          <div class="container">
              <div class="row">
                  <div class="col-md-10 mx-auto" v-if="sectionthreedata.contenthtml">
                      <div class="section-title-grp text-center" v-html="sectionthreedata.contenthtml"></div>
                  </div>
                  <div class="col-md-6" v-for="(img, index) in sectionthreedata.images" :key="index">
                      <div class="img-full">
                        <img width="100%" height="100%" :src="getImgUrl(img)" :alt="'Unmatched Quality Assurance ' + (index + 1)">
                      </div>
                  </div>
                  <div class="col-md-12 text-center mt-5">
                      <a class="btn glb-btn" :href="sectionthreedata.button_link != '' ? `${sectionthreedata.button_link}` : '#'">{{ sectionthreedata.button_text }}</a>
                  </div>
              </div>
          </div>
      </section>

      <section class="home-section-bg home-brand-slider-bg">
          <div class="container">
              <div class="row">
                  <div class="col-md-12 mx-auto">
                      <div class="section-title-grp text-center">
                          <div class="section-title">
                              <h2>TRUSTED AND LICENSED BY<span>TOP BRANDS NATIONWIDE</span></h2>
                          </div>
                      </div>
                  </div>

                  <div class="col-md-12 text-center mt-4">
                      <div class="owl-carousel owl-theme" id="brand-slider" v-if="brands.length">
                          <div class="item" v-for="(brand, index) in brands" :key="brand.brand_id">
                            <a v-if="brand.brand_url" :href="brand.brand_url" target="_blank">
                                <img width="100%" height="100%" :src="getImgUrl(brand.brand_image)" :alt="'Brand ' + (index + 1)">
                            </a>
                            <img v-else width="100%" height="100%" :src="getImgUrl(brand.brand_image)" :alt="'Brand ' + (index + 1)">
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </section>

      <GetInTouchElement></GetInTouchElement>
    </layout>
</template>
 
<script>
import 'owl.carousel/dist/assets/owl.carousel.css';
import $ from 'jquery';
import 'owl.carousel';
import axios from 'axios';
import Layout from '../Layout/Layout.vue';
import GetInTouchElement from '../Elements/GetInTouchElement.vue';
 
export default {
  name: 'HomeComponent',
  components: {
    Layout,
    GetInTouchElement
  },
  computed: {
    backgroundImageStyle() {
      if (this.sectiontwodata.img) {
        return {
            backgroundImage: `url('${process.env.VUE_APP_IMG_URL}/${this.sectiontwodata.img}')`
        };
      }
      return '';
    },
    storeConfigurationData() {
      return this.$store.state.storeConfigurationData;
    },
    storeInformationData() {
      return this.$store.state.storeInformationData;
    },
  },
  watch: {
    storeInformationData(newData) {
      this.updatePageTitle(newData);
    }
  },
  data() {
    return {
        banners: [],
        categories: [],
        brands: [],
        sectiononedata:[],
        sectiontwodata:[],
        sectionthreedata:[],
        imageVersion: 1
    }
  },
  created() {
    if (!this.storeInformationData) {
      this.$store.dispatch('fetchStoreInformation');
    }
    //document.title = 'LXG - Home';
    this.fetchBannerList();
    this.fetchCategoryList();
    this.fetchBrandsList();
    this.fetchSectionData();
  },
  async mounted() {
    await this.fetchImageVersion();
  },
  methods: {
    fetchBannerList() {
      axios.get('/get-banners', { responseType: 'json' })
        .then(response => {
            this.banners = response.data.data;
            this.$nextTick(() => {
                this.initBannerCarousel();
            });
        })
        .catch(error => {
          return error
        });
    },
    fetchCategoryList() {
      axios.get('/get-home-categories', { responseType: 'json' })
        .then(response => {
            this.categories = response.data.data;
        })
        .catch(error => {
          return error
        });
    },
    fetchBrandsList(){
        axios.get('/get-store-brands', { responseType: 'json' })
        .then(response => {
            this.brands = response.data.data;
            this.$nextTick(() => {
                this.initBrandCarousel();
            });
        })
        .catch(error => {
          return error
        });
    },
    fetchSectionData() {
      axios.get('/get-home-section', { responseType: 'json' })
        .then(response => {
            this.sectiononedata = response.data.sectiononedata;
            var Html = $.parseHTML(this.sectiononedata.content);
            this.sectiononedata.contenthtml = Html[0].data;
            this.$nextTick(() => {
                this.initHomeSectionOneCarousel();
            });

            this.sectiontwodata = response.data.sectiontwodata;
            var Html1 = $.parseHTML(this.sectiontwodata.content);
            this.sectiontwodata.contenthtml = Html1[0].data;

            this.sectionthreedata = response.data.sectionthreedata;
            var Html2 = $.parseHTML(this.sectionthreedata.content);
            this.sectionthreedata.contenthtml = Html2[0].data; 
        })
        .catch(error => {
          return error
        });
    },
    parseJSON(str) {
        if(str == '') {
            return '';
        }
        return JSON.parse(str);
    },
    bannerHeading(str){
       var attributes = JSON.parse(str);
       var html = attributes.image_text;
       if(html == '' || html == null){
            return '';
       }
       if(attributes.banner_link != '' && attributes.banner_link != null){
        return '<a href="'+attributes.banner_link+'">'+html+'</a>';
       }
       return html;
    },
    async fetchImageVersion(){
      if (!this.storeConfigurationData) {
        this.$store.dispatch('fetchStoreConfiguration');
      }
    },
    getImgUrl(imgDBPath){
        if(this.storeConfigurationData){
          this.imageVersion = this.storeConfigurationData.css_js_version;
        }
        if(imgDBPath == '' || imgDBPath == null){
            return process.env.VUE_APP_IMG_URL+'/images/store_images/coming-soon.jpg';
        }
        return process.env.VUE_APP_IMG_URL+'/'+imgDBPath+'?v='+this.imageVersion;
    },
	getClass(position){
        if(position == 'right') {
            return 'text-end';
        } else if(position == 'center'){
            return 'text-center';
        } else {
            return 'text-start';
        }
    },
    callPhone(ph){
        //ph = ph.replace(/\D/g,"");
        //return `tel:${ph}`;
        return ph;
    },
    updatePageTitle(data) {
      if (data && data.store_name) {
        document.title = data.store_name + ' - Home';
      }
    },
    initBannerCarousel(){
        $('#banner-inner-div').owlCarousel({
            loop: true,
            nav: false,
            dots: true,
            items: 1,
            autoplay: false,
            autoplayTimeout: 2500,
            autoplayHoverPause: true,
        });
    },
    initBrandCarousel(){
        // Home Brand Sliser JS
        $('#brand-slider').owlCarousel({
            loop: true,
            nav: true,
            dots: false,
            autoplay: true,
            autoplayTimeout: 2500,
            autoplayHoverPause: true,
            margin: 25,
            responsive: {
                0: {
                    items: 2
                },
                767: {
                    items: 3
                },
                992: {
                    items: 4
                },
                1025: {
                    items: 5
                },
                1300: {
                    items: 6
                }
            },
            navText: ['<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-left" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/></svg>', '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/></svg>']
        });
    },
    initHomeSectionOneCarousel(){
        $('#perfect-slider').owlCarousel({
            loop: false,
            nav: true,
            dots: true,
            margin: 20,
            responsive: {
                0: {
                    items: 1
                },
                600: {
                    items: 1
                },
                1000: {
                    items: 2
                }
            },
            navText: ['<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-left" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/></svg>', '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/></svg>']
        });
    },
  },
  errorCaptured(error, vm, info) {
    // Custom error handling logic here
    console.error('Error captured:', error);
    console.error('Vue instance:', vm);
    console.error('Vue info:', info);
    
    // Return false to stop the error from propagating further
    return false;
  }
};
</script>