// src/directives/smooth-scroll.js
export default {
  bind(el) {
    el.addEventListener('click', function(e) {
      const targetId = el.getAttribute('href');
      if (targetId.startsWith('#') && targetId !== '#') {
        e.preventDefault();
        const targetElement = document.querySelector(targetId);
        if (targetElement) {
          targetElement.scrollIntoView({
            behavior: 'smooth'
          });
        }
      }
    });
  }
};