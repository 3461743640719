<template>
  <layout>
    
	<section class="hero-slider-bg">
    <div class="w-100 owl-carousel owl-theme" id="banner-inner-div">
        <div class="item" v-for="banner in banners" :key="banner.banner_id">
            <div class="owl-text-overlay">
                <div class="container">
                    <h1 class="owl-title" v-if="parseJSON(banner.image_attributes).text_position == 'left'" v-html="parseJSON(banner.image_attributes).image_text"></h1>
                    <h1 class="owl-title end" v-if="parseJSON(banner.image_attributes).text_position == 'right'" v-html="parseJSON(banner.image_attributes).image_text"></h1>
                    <h1 class="owl-title text-center" v-if="parseJSON(banner.image_attributes).text_position == 'center'" v-html="parseJSON(banner.image_attributes).image_text"></h1>
                </div> 
            </div>
            <img :src="getImgUrl(banner.store_banner)" :alt="(banner.banner_id)" />
        </div>
        
    </div>
</section>

    <section class="home-section-bg home-category-bg" v-if="categories.length">
        <div class="container">
            <div class="cate-title">
                <h2><span>Category</span></h2>
            </div>
            <div class="row pt-md-4">
                <div class="col-md-4" v-for="category in categories" :key="category.category_id">
                    <div class="cate-box pt-4 pt-md-0">
                        <div class="cate-img">
                            <img :src="getImgUrl(category.category_image)" :alt="(category.category_name)">
                        </div>
                        <div class="cate-txt">
                            <h3>{{category.category_name}}</h3>
                            <a :href="`/${category.category_slug}`"><i class="fa fa-long-arrow-right" aria-hidden="true"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="home-section-bg home-perfect-bg">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-md-4">
                    <div class="section-title-grp">
                        <div class="section-title">
                            <h2>PERFECT<span>PACKAGING</span></h2>
                        </div>
                        <p>Whether it’s picked up in a store, or delivered to a home or office, when our product arrives in your customer’s hands we want it to convey quality and information. Our product packaging includes specifications, features, instructions, and is mailed in UPS/FedEx approved damage-resistant boxes</p>
                        <a href="#" class="btn glb-btn">See our Catalog</a>
                    </div>
                </div>
                <div class="col-md-8 pt-4 pt-md-0">
                    <div class="owl-carousel owl-theme" id="perfect-slider">
                        <div class="item">
                            <img src="@/assets/images/per-1.jpg" alt="Home Banner 1" />
                        </div>
                        <div class="item">
                            <img src="@/assets/images/per-2.jpg" alt="Home Banner 1" />
                        </div>
                        <div class="item">
                            <img src="@/assets/images/per-1.jpg" alt="Home Banner 1" />
                        </div>
                        <div class="item">
                            <img src="@/assets/images/per-2.jpg" alt="Home Banner 1" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

      <section class="home-section-bg home-text-img-bg position-relative">
          <div class="container position-relative">
              <div class="row align-items-center">
                  <div class="col-md-auto">
                      <div class="section-title-grp">
                          <div class="section-title">
                              <h2>FLEXIBLE SOLUTIONS TO<span>MEET YOUR NEEDS</span></h2>
                          </div>
                          <p>Our company takes pride in saying “yes!” to any order. We understand that no two businesses are the same.</p>
                          <a href="#" class="btn glb-btn">Call us to discuss your specific needs: 877-382-6594</a>
                      </div>
                  </div>
              </div>
          </div>
      </section>

      <section class="home-section-bg home-quality-bg">
          <div class="container">
              <div class="row">
                  <div class="col-md-10 mx-auto">
                      <div class="section-title-grp text-center">
                          <div class="section-title">
                              <h2>UNMATCHED QUALITY<span>ASSURANCE</span></h2>
                          </div>
                          <p>All LXG, Inc. products are manufactured and shipped directly from our facility in Tempe, AZ. Our company has 30+ years of experience in product development, sourcing, logistics, sales, production, and shipping. We have developed a detailed 10-point quality assurance process to ensure your order is delivered quickly and correctly.</p>
                      </div>
                  </div>
                  <div class="col-md-6">
                      <div class="img-full">
                          <img src="@/assets/images/quality-1.jpg" alt="" />
                      </div>
                  </div>
                  <div class="col-md-6">
                      <div class="img-full">
                          <img src="@/assets/images/quality-2.jpg" alt="" />
                      </div>
                  </div>
                  <div class="col-md-12 text-center mt-5">
                      <a href="#" class="btn glb-btn">Learn More...</a>
                  </div>
              </div>
          </div>
      </section>

      <section class="home-section-bg home-brand-slider-bg">
          <div class="container">
              <div class="row">
                  <div class="col-md-12 mx-auto">
                      <div class="section-title-grp text-center">
                          <div class="section-title">
                              <h2>TRUSTED AND LICENSED BY<span>TOP BRANDS NATIONWIDE</span></h2>
                          </div>
                      </div>
                  </div>

                  <div class="col-md-12 text-center mt-4">
                      <div class="owl-carousel owl-theme" id="brand-slider" v-if="brands.length">
                          <div class="item" v-for="(brand, index) in brands" :key="brand.brand_id">
                              <img :src="getImgUrl(brand.brand_image)" :alt="'Brand ' + (index + 1)">
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </section>

      <GetInTouchElement></GetInTouchElement>
    </layout>
</template>
 
<script>
import 'owl.carousel/dist/assets/owl.carousel.css';
import $ from 'jquery';
import 'owl.carousel';
import axios from 'axios';
import Layout from '../Layout/Layout.vue';
import GetInTouchElement from '../Elements/GetInTouchElement.vue';
 
export default {
  name: 'HomePageComponent',
  components: {
    Layout,
    GetInTouchElement
  },
  data() {
    return {
        banners: [],
        categories: [],
        brands: []
    }
  },
  mounted() {
    this.getBannerData();
  },
  created() {
    document.title = 'LXG - Home Page',
    this.fetchCategoryList();
    this.fetchBrandsList();
  },
  methods: {
    async getBannerData() {
      try {
        const response = await axios.get('/get-banner-data', { responseType: 'json' });
        this.banners = response.data.data;
		this.$nextTick(() => {
                this.initBannerCarousel();
            });
      } catch (error) {
        console.error('There was an error!', error);
      }
    },
    fetchCategoryList() {
      axios.get('/get-home-categories', { responseType: 'json' })
        .then(response => {
            this.categories = response.data.data;
        })
        .catch(error => {
          return error
        });
    },
    fetchBrandsList(){
        axios.get('/get-store-brands', { responseType: 'json' })
        .then(response => {
            this.brands = response.data.data;
            this.$nextTick(() => {
                this.initBrandCarousel();
            });
        })
        .catch(error => {
          return error
        });
    },
    parseJSON(str) {
        if(str == '') {
            return '';
        }
        return JSON.parse(str);
    },
    getImgUrl(imgDBPath){
        if(imgDBPath == '' || imgDBPath == null){
            return process.env.VUE_APP_IMG_URL+'/images/store_images/comming-soon.jpg';
        }
        return process.env.VUE_APP_IMG_URL+'/'+imgDBPath;
    },
    initBannerCarousel(){
        $('#banner-inner-div').owlCarousel({
            loop: true,
            nav: false,
            dots: true,
            items: 1,
            autoplay: false,
            autoplayTimeout: 2500,
            autoplayHoverPause: true,
        });
    },
    initBrandCarousel(){
        // Home Brand Sliser JS
        $('#brand-slider').owlCarousel({
            loop: true,
            nav: true,
            dots: false,
            autoplay: true,
            autoplayTimeout: 2500,
            autoplayHoverPause: true,
            margin: 25,
            responsive: {
                0: {
                    items: 2
                },
                767: {
                    items: 3
                },
                992: {
                    items: 4
                },
                1025: {
                    items: 5
                },
                1300: {
                    items: 6
                }
            },
            navText: ['<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-left" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/></svg>', '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/></svg>']
        });
    }
  },
  errorCaptured(error, vm, info) {
    // Custom error handling logic here
    console.error('Error captured:', error);
    console.error('Vue instance:', vm);
    console.error('Vue info:', info);
    
    // Return false to stop the error from propagating further
    return false;
  }
};
</script>