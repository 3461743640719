<template>
  <header class="header-bg"> 
    <div class="container">
        <div class="logo-bg">
            <a href="/"><img width="100%" height="100%" alt="Logo Image" v-if="storeInformationData" :src="getImgUrl(storeInformationData.store_logo)" /></a>
        </div>
        <div :class="['main-menu-bg', { 'show-menu': isMenuVisible }]" v-if="storeMenuData">
            <!--<ul class="main-ul mpl-zero" v-if="storeMenuData.length">
                <li class="nav-item" v-for="menu in storeMenuData" :key="menu.item_id">
                    <a v-if="menu.menu_location === 1 && menu.item_text == 'Contact Us'" :href="`#get_in_touch_id`" class="nav-link">{{menu.item_text}}</a>
                    <a v-else-if="menu.menu_location === 1 || menu.menu_location === 3" :href="menu.item_link != null ? `/${menu.item_link}` : '#'" class="nav-link">{{menu.item_text}}</a>
                    <ul class="sub-menu mpl-zero" v-if="menu.submenu.length">
                        <li class="nav-item" v-for="smenu in menu.submenu" :key="smenu.item_id">
                            <a :href="smenu.item_link != null ? `/${smenu.item_link}` : '#'" class="nav-link">{{smenu.item_text}}</a>
                        </li>
                    </ul>
                    <ul class="sub-menu mpl-zero" v-if="menu.item_text == 'Categories'">
                        <li class="nav-item" v-for="category in menu.allcategories" :key="category.category_id">
                            <a :href="`/category/${category.category_slug}`" class="nav-link">{{category.category_name}}</a>
                        </li>
                    </ul>
                </li>
            </ul>-->
            <!--<ul class="main-ul mpl-zero" v-if="storeMenuData.length">
              <submenu v-for="menuItem in storeMenuData" :key="menuItem.item_id" :menu-item="menuItem"></submenu>
            </ul>-->
            <ul class="main-ul mpl-zero" v-if="storeMenuData.length">
              <li v-for="menuItem in storeMenuData" :key="menuItem.item_id" :class="['nav-item', { 'active': isActive(menuItem) }]">
                <a 
                  :target="menuItem.item_target || '_self'"
                  v-if="menuItem.menu_location === 1 && menuItem.item_text === 'Contact'"
                  :href="'#get_in_touch_id'"
                  class="nav-link"
                >{{ menuItem.item_text }}</a>
                
                <a 
                  :target="menuItem.item_target || '_self'"
                  v-else-if="menuItem.menu_location !== 2"
                  :href="menuItem.item_link ? `/${menuItem.item_link}` : '#'"
                  class="nav-link"
                >{{ menuItem.item_text }}</a>
                
                <div 
                  class="child-trigger"
                  :class="{ 'child-open': isOpen(menuItem) }"
                  @click="toggleSubMenu(menuItem)"
                  v-if="menuItem.submenu && menuItem.submenu.length"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"/>
                  </svg>
                </div>
                
                
                  <ul :class="{ 'd-block': isOpen(menuItem) }" 
                    v-if="menuItem.submenu && menuItem.submenu.length"
                    class="sub-menu mpl-zero"
                  >
                    <Submenu 
                      v-for="submenuItem in menuItem.submenu"
                      :key="submenuItem.item_id"
                      :menu-item="submenuItem"
                    ></Submenu>
                  </ul>
                
              </li>
            </ul>
        </div>
        <div class="header-right-bg">
            <ul class="mpl-zero mpl-zero d-flex align-items-center">
                <li class="head-mobile">
                    <a :href="callPhone(storeConfigurationData.contact_number)" v-if="storeConfigurationData"><i class="fa fa-phone" aria-hidden="true"></i> <span>{{ storeConfigurationData.contact_number }}</span></a>
                </li>
                <li class="mobile-bar" @click="toggleMenu">
                    <a :class="['ms-1', { 'd-none': !isCloseVisible }]" href="javascript:void(0);">
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-list" viewBox="0 0 16 16">
                          <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"/>
                        </svg>
                    </a>
                    <a :class="['ms-1', { 'd-none': isCloseVisible }]" href="javascript:void(0);">
                      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-x-circle" viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
                      </svg>
                    </a>
                </li>
            </ul>
        </div>
      </div>
  </header>

  <div :class="['get-in-touch-btn', { 'fade-in': showButton, 'fade-out': !showButton }]" >
      <a href="#get_in_touch_id" v-smooth-scroll class="btn glb-btn">Get in Touch</a>
  </div>
</template>
<script>
import Submenu from '../../Elements/SubmenuElement.vue';
import { mapState, mapActions } from 'vuex';
/* eslint-disable */
export default {
  name: 'HeaderComponent',
  components: {
    Submenu
  },
  data() {
    return {
        imageVersion: 1,
        isMenuVisible: false,
        isCloseVisible: true,
        openSubMenus: []
    }
  },
  watch: {
    storeInformationData(newVal, oldVal) {
      if (newVal) {
        this.updateFavicon(newVal, oldVal);
      }
    },
    isMainMenuVisible(newVal){
      if (!newVal && this.isMenuVisible) {
        //this.toggleMenu();
        this.isMenuVisible = !this.isMenuVisible;
        this.isCloseVisible = !this.isCloseVisible;
        this.setMainMenuVisible(this.isMenuVisible);
        if (this.isMenuVisible) {
          document.body.classList.add('overflow-hidden');
        } else {
          document.body.classList.remove('overflow-hidden');
        }
      }
    }
  },
  computed: {
    storeConfigurationData() {
      return this.$store.state.storeConfigurationData;
    },
    storeInformationData() {
      return this.$store.state.storeInformationData;
    },
    storeMenuData() {
      return this.$store.state.storeMenuData;
    },
    showButton() {
      return this.$store.getters.showButton;
    },
    currentUrl() {
      return window.location.pathname;
    },
    ...mapState(['isMainMenuVisible'])
  },
  created() {
    if (!this.storeConfigurationData) {
      this.$store.dispatch('fetchStoreConfiguration');
    }
    if (!this.storeInformationData) {
      this.$store.dispatch('fetchStoreInformation');
    }
    if (!this.storeMenuData) {
      this.$store.dispatch('fetchStoreMenu');
    }
  },
  async mounted() {
    await this.fetchImageVersion();
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    ...mapActions(['setMainMenuVisible', 'setCategoryPopupVisible']),
    updateFavicon(newStoreInfo, oldStoreInfo) {
      const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
      link.type = 'image/x-icon';
      link.rel = 'shortcut icon';
      link.href = process.env.VUE_APP_IMG_URL+'/'+newStoreInfo.favicon+'?v='+new Date().getTime();
      document.getElementsByTagName('head')[0].appendChild(link);
    },
    async fetchImageVersion(){
      if (!this.storeConfigurationData) {
        this.$store.dispatch('fetchStoreConfiguration');
      }
    },
    getImgUrl(imgDBPath){
        if(this.storeConfigurationData){
          this.imageVersion = this.storeConfigurationData.css_js_version;
        }
        return process.env.VUE_APP_IMG_URL+'/'+imgDBPath+'?v='+this.imageVersion;
    },
    callPhone(ph){
        ph = ph.replace(/\D/g,"");
        return `tel:${ph}`;
    },
    toggleMenu() {
      this.isMenuVisible = !this.isMenuVisible;
      this.isCloseVisible = !this.isCloseVisible;
      this.setMainMenuVisible(this.isMenuVisible);
      this.setCategoryPopupVisible(false);
      if (this.isMenuVisible) {
        document.body.classList.add('overflow-hidden');
      } else {
        document.body.classList.remove('overflow-hidden');
      }
    },
    toggleSubMenu(menuItem) {
      const index = this.openSubMenus.indexOf(menuItem.item_id);
      if (index > -1) {
        this.openSubMenus.splice(index, 1);
      } else {
        this.openSubMenus.push(menuItem.item_id);
      }
    },
    isOpen(menuItem) {
      return this.openSubMenus.includes(menuItem.item_id);
    },
    isActive(menuItem) {
      return `/${menuItem.item_link}` === this.currentUrl;
    },
    closeMainMenu() {
      this.$store.dispatch('closeMainMenu');
    }
  }
}
</script>