<template>
  <HeaderComponent />
    <slot></slot>
  <footer-component />
</template>
 
<script>
import HeaderComponent from './Header/Header.vue'
import FooterComponent from './Footer/Footer.vue'
export default {
    name: 'LayoutComponent',
    components: {
      HeaderComponent,
      FooterComponent
    }
};
</script>